import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection";
import FormProduct from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct";
import ListOfProductsToSetParams from "./ListProductsToSetParams";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";

export default function SetParamProdToPost({
  isLoad,
  setIsLoad,
  paramsToSend,
  setParamsToSend,
  listinType,
  setListinType,
  category
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const globalContext = useContext(GlobalContext);
  const [blockField, setBlockField] = useState(true);

  useEffect(() => {

    if(paramsToSend.length === 0) globalContext.setModalPostProduct(false)

  }, [paramsToSend]);

  const sendToFormData = async (v, currentPosition) => {
    setCurrentIndex(currentPosition);
  };

  const titleFunc = (v) => {
    console.log(paramsToSend)
    if(paramsToSend.length > 0){
      let copyArray = [...paramsToSend];
      copyArray[currentIndex].Title = v;
      setParamsToSend(copyArray);
    }
  };

  const brandFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].BrandName = v;
    setParamsToSend(copyArray);
  };
  const modelFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].sku = v;
    setParamsToSend(copyArray);
  };
  const packagedHeight = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedHeight = v;
    setParamsToSend(copyArray);
  };
  const packagedLength = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedLength = v;
    setParamsToSend(copyArray);
  };
  const packagedWidth = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedWidth = v;
    setParamsToSend(copyArray);
    console.log('currentIndex')
    console.log(copyArray[currentIndex].PackagedWidth)
    console.log(currentIndex)
    console.log('currentIndex')
  };
  const packagedWeightKg = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedWeightKg = v;
    setParamsToSend(copyArray);
  };

  //De no existir el atributo specs, este se crea como un arreglo
  const initializeSpecsIfNeeded = () => {
    let copyArray = [...paramsToSend];
    if (!copyArray[currentIndex].specs) {
        copyArray[currentIndex].specs = []; // Asegúrate de que specs sea un arreglo
    }
    return copyArray;
  };

  //Agregamos o actualizamos cierta especificacion al arreglo
  const addOrUpdateSpec = (name, id, value) => {
      let copyArray = initializeSpecsIfNeeded();
      let aux = [];
      aux[0]=value;
      // Verificamos si ya existe la especificación
      const existingSpecIndex = copyArray[currentIndex].specs.findIndex(spec => spec.Id === id);
      
      if (existingSpecIndex !== -1) {
          // Si ya existe, actualizamos su valor
          copyArray[currentIndex].specs[existingSpecIndex].Value = aux;
      } else {
          // Si no existe, la agregamos al arreglo
          copyArray[currentIndex].specs.push({ Value: aux, Id: id, Name: name });
      }
      
      setParamsToSend(copyArray);
  };

  const warranty = (v) => {
      addOrUpdateSpec("Garantía", 18, v);
  };

  const manufacturer = (v) => {
      addOrUpdateSpec("Fabricante y/o Importador", 53, v);
  };

  const condition = (v) => {
      addOrUpdateSpec("Condición del producto", 160, v);
  };

  const material = (v) => {
      addOrUpdateSpec("Material", 161, v);
  };

  const seller = (v) => {
      addOrUpdateSpec("Vendedor", 170, v);
  };

  const returns = (v) => {
      addOrUpdateSpec("Devoluciones", 171, v);
  };

  const termsAndConditions = (v) => {
      addOrUpdateSpec("Términos y Condiciones", 175, v);
  };

  const countryOfOrigin = (v) => {
      addOrUpdateSpec("País de origen", 52, v);
  };



  
  function formatText(text) {
    text = text.replace(/CARACTER[ÍI]STICAS/g, '\n\nCARACTERÍSTICAS:\n');
    return text.replace(/ - /g, '\n- ');
  }
  const bodyFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].Description = v;
    setParamsToSend(copyArray);
  };
  const bodySeoFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].bodySeo = v;
    setParamsToSend(copyArray);
    console.log(copyArray)
    console.log(paramsToSend)
  };
  

  console.log("validamos el index", currentIndex, paramsToSend);

  //Inicializar el valor por defecto en el arreglo ParamsToSend
  useEffect(() => {
    setParamsToSend(prevParams => prevParams.map(product => ({
      ...product,
      specs: [
        { Id: 18, Name: "Garantía", Value: ["3 meses"] },
        { Id: 53, Name: "Fabricante y/o Importador", Value: ["Internacional DMC 2050 SAS"] },
        { Id: 160, Name: "Condición del producto", Value: ["Nuevo y original"] },
        { Id: 170, Name: "Vendedor", Value: ["IDMC"] },
        { Id: 171, Name: "Devoluciones", Value: ["Si tu producto es defectuoso, podrás dirigirte a nuestro Departamento de Servicio al Cliente, en donde luego de una evaluación técnica, se determinará la aplicabilidad de la garantía legal."] },
        { Id: 175, Name: "Términos y Condiciones", Value: ["Tres (3) meses de garantía, aplicando ciertas condiciones."] },
        { Id: 52, Name: "País de origen", Value: ["China"] }
      ]
    })));
  }, []);

  return (
    <div style={{ width: "100%", height: "auto" }}>
      <ListOfProductsToSetParams sendToFormData={sendToFormData} paramsToSend={paramsToSend} setParamsToSend={setParamsToSend}  />
      {paramsToSend.length > 0 && (
        <FormProduct.ContForms>
          {/* <FormProduct.Contform> */}
            {/* <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Parametros Generales
              </Text>
            </FormProduct.HeaderContForm> */}
            {/* <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel w='340px' mr='5px'>
                <Text size='12pt' fw='500'>
                  Tipo de cuota
                  <sup>*</sup>
                </Text>
                <FormProduct.Select
                  onChange={(e) => {
                    setListinType(e.target.value)
                  }}
                >
                  <option value=''>Seleccionar</option>
                  {cuotasCashea.map((cuota)=>(
                    <option key={cuota} value={cuota}>{cuota} meses</option>
                  ))}
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm> */}
          {/* </FormProduct.Contform> */}

          <FormProduct.Contform>
            <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Llene los parametros para el producto a publicar
              </Text>
            </FormProduct.HeaderContForm>
            <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel mr='5px'>
                <Text size='12pt' fw='500'>
                  Titulo de la Publicacion
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='Titulo de la Publicacion'
                  value={paramsToSend[currentIndex]?.Title || ""}
                  onChange={(e) => titleFunc(e.target.value)}
                  maxLength={60}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Precio
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  placeholder='Precio para la publicacion'
                  value={paramsToSend[currentIndex]?.Price || ""}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Marca de Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='La marca para el producto'
                  value={paramsToSend[currentIndex]?.BrandName || ""}
                  onChange={(e) => brandFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              {/* <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Referencia interna
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='Referencia interna'
                  value={paramsToSend[currentIndex].sku || ""}
                  onChange={(e) => modelFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel> */}
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Categoria seleccionada
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  value={category.name}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Altura empaquetada del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Altura empaquetada del Producto'
                  value={paramsToSend[currentIndex]?.PackagedHeight || ""}
                  onChange={(e) => packagedHeight(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Longitud empaquetada del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Longitud empaquetada del Producto'
                  value={paramsToSend[currentIndex]?.PackagedLength || ""}
                  onChange={(e) => packagedLength(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Ancho empaquetado del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Ancho empaquetado del Producto'
                  value={paramsToSend[currentIndex]?.PackagedWidth || ""}
                  onChange={(e) => packagedWidth(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                    Peso Empaquetado Kg del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Peso Empaquetado Kg'
                  value={paramsToSend[currentIndex]?.PackagedWeightKg || ""}
                  onChange={(e) => packagedWeightKg(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              
              <FormProduct.ContInputAndLabel w='340px'>
                  <Text size='12pt' fw='500'>
                      Garantia
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='La garantia del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 18)?.Value[0] || ""}
                      onChange={(e) => warranty(e.target.value)}
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                  <Text size='12pt' fw='500'>
                      Fabricante y/o importador
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='El fabricante y/o importador del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 53)?.Value[0] || ""}
                      onChange={(e) => manufacturer(e.target.value)}
                      disabled
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                  <Text size='12pt' fw='500'>
                      Condicion del producto
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='La condicion del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 160)?.Value[0] || ""}
                      onChange={(e) => condition(e.target.value)}
                      disabled
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                  <Text size='12pt' fw='500'>
                      Material
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='El material del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 161)?.Value[0] || ""}
                      onChange={(e) => material(e.target.value)}
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                  <Text size='12pt' fw='500'>
                      Vendedor
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='El vendedor del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 170)?.Value[0] || ""}
                      onChange={(e) => seller(e.target.value)}
                      disabled
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                  <Text size='12pt' fw='500'>
                      Pais de origen
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='El pais de origen del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 52)?.Value[0] || ""}
                      onChange={(e) => countryOfOrigin(e.target.value)}
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel >
                  <Text size='12pt' fw='500'>
                      Terminos y condiciones
                      <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                      placeholder='Los terminos y condiciones'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 175)?.Value[0] || ""}
                      onChange={(e) => termsAndConditions(e.target.value)}
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel style={{marginTop: '10px', overflow: 'hidden'}}>
                  <Text size='12pt' fw='500'>
                      Devoluciones
                      <sup>*</sup>
                  </Text>
                  <FormProduct.TextArea style={{height: 60, backgroundColor: '#e5e5e596', cursor: 'pointer'}}
                      placeholder='La devolucion del producto'
                      value={paramsToSend[currentIndex]?.specs?.find(spec => spec.Id === 171)?.Value[0] || ""}
                      onChange={(e) => returns(e.target.value)}
                      disabled
                  />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel style={{marginTop: '10px', overflow: 'hidden'}} >
                  <Text size='12pt' fw='500'>
                    Descripcion Breve del producto para SEO
                  </Text>
                  <FormProduct.TextArea style={{height: '100px'}}
                    placeholder='Descripcion para el producto'
                    value={paramsToSend[currentIndex]?.bodySeo || ""}
                    onChange={(e) => bodySeoFunc(e.target.value)}
                  />
              </FormProduct.ContInputAndLabel >

              <FormProduct.ContInputAndLabel style={{marginTop: '20px', overflow: 'hidden'}}>
                  <Text size='12pt' fw='500'>
                    Descripcion completa del Producto
                  </Text>
                  <FormProduct.TextArea
                    placeholder='Descripcion para el producto'
                    value={formatText(paramsToSend[currentIndex]?.Description) || ""}
                    onChange={(e) => bodyFunc(e.target.value)}
                  />
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm>
          </FormProduct.Contform>
        </FormProduct.ContForms>
      )}
    </div>
  );
}
