import React, { useState } from 'react';
import Select from 'react-select';
import RC_Style from '../../../../../assets/css/components/admin/plugins/reportesComercialesStyle';

const SelectRate = ({ setRate, rate, label, rates, multi, isArrayString }) => {

    const [selectedOptions, setSelectedOptions] = useState([]);
    let options = [];
    if(rates){
        options = rates.map(rt => ({
            value: !isArrayString ? rt.id : rt,
            label: !isArrayString ? rt.name : rt,
        }));
    }

    const handleChange = (selected) => {
        setSelectedOptions(selected);
        const arrayString = selected.map(option => option.value)
        setRate(arrayString);
    };

    return (
        <>
            {
                !multi ? (
                    <div style={{ marginBottom: '15px' }}>
                        <RC_Style.Label htmlFor="rate">Seleccionar tarifa {label}</RC_Style.Label>
                        <Select
                            id="rate"
                            value={rate && options.find(option => option.value === rate.value)}
                            onChange={(selectedOption) => setRate(selectedOption)}
                            options={options}
                            styles={RC_Style.customStyles}
                            isClearable={false}
                        />
                    </div>
                ) : (
                    <div>
                        <RC_Style.Label htmlFor="rate">Seleccione {label} a excluir</RC_Style.Label>
                        <Select
                            options={options}
                            isMulti
                            value={selectedOptions}
                            onChange={handleChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                )
            }

            
        
        </>
    );
};

export default SelectRate;