import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import { keyUserId, keyAccessToken } from "../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";
import DateTime from "../admin/cron-reports/components/DateTime";
import getAttribodoo from "../admin/plugins/woo/functions/getAttribOdoo";

export default function TestAdmin() {
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [inputs, setInputs] = useState({});
  const [redirect, setRedirect] = useState(null);
  const [pageReport, setPageReport] = useState(1);
  const [limitReport, setLimitReport] = useState(1000);
  const [dateReportUrl, setDateReportUrl] = useState("");

  // useEffect(() => {
  //     const hasAccess = verifyAccessRole(Routes, 64);
  //     if(!hasAccess) {
  //         setRedirect(
  //             <Redirect to={`/admin/dashboard`} />
  //         )
  //     }
  // },[]);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error

    if (err.response && err.response.data) {
      if (err.response.data.message) {
        globalContext.showModalWithMsg(err.response.data.message);
      } else {
        globalContext.showModalWithMsg(err.response.data);
      }
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  const updateInputState = (e) => {
    const name = e.target.name;
    const newState = Object.assign({}, inputs);

    switch (e.target.type) {
      case "checkbox":
        newState[name] = e.target.checked;
        break;

      default:
        newState[name] = e.target.value;
        break;
    }

    setInputs(newState);
  };

  const downloadCategories = () => {
    if (!inputs.mlCountry) {
      globalContext.showModalWithMsg("El País de ML");
      return;
    }

    const processCheckIntervalTime = 5000;
    const headers = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    globalContext.setLoaderPage(true);
    axios
      .post(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/v1/ml-category/sync/${inputs.mlCountry.toLowerCase()}`,
        {},
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;

        if (data.monitor) {
          //If the process ID was received, start monitoring
          let waitingMonitorResponse = false;
          const interval = setInterval(() => {
            if (!waitingMonitorResponse) {
              waitingMonitorResponse = true;

              axios
                .get(
                  `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                  {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                  }
                )
                .then((resp) => {
                  const data = resp.data.data;

                  if (data.advance >= 100) {
                    clearInterval(interval);
                    globalContext.showModalWithMsg(`Se descargaron`);
                    globalContext.setLoaderPage(false);
                  } else {
                    const advance = data.advance.toFixed(2);
                    globalContext.setLoaderPage(
                      true,
                      `${advance}% (Categorias: ${data.data.savedCategories}/${data.data.totalCategories}) (Subcategorias: ${data.data.savedSubcategories}/?)`
                    );
                  }
                })
                .catch((err) => {
                  handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                  );
                  clearInterval(interval);
                  globalContext.setLoaderPage(false);
                })
                .finally(() => {
                  waitingMonitorResponse = false;
                });
            }
          }, processCheckIntervalTime);
        } else {
          globalContext.setLoaderPage(false);
        }
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const downloadAmazonCategories = () => {
    if (!inputs.numAmazonLevel) {
      globalContext.showModalWithMsg("El numero de niveles");
      return;
    }

    const processCheckIntervalTime = 5000;
    const headers = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/parse-categories`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
          params: {
            maxDepth: inputs.numAmazonLevel,
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;

        if (data.monitor) {
          //If the process ID was received, start monitoring
          let waitingMonitorResponse = false;
          const interval = setInterval(() => {
            if (!waitingMonitorResponse) {
              waitingMonitorResponse = true;

              axios
                .get(
                  `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                  {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                  }
                )
                .then((resp) => {
                  const data = resp.data.data;

                  if (data.advance >= 100) {
                    clearInterval(interval);
                    globalContext.showModalWithMsg(`Se descargaron`);
                    globalContext.setLoaderPage(false);
                  } else {
                    const advance = data.advance.toFixed(2);
                    globalContext.setLoaderPage(
                      true,
                      `${advance}% (Categorias: ${data.data.savedCategories}/${data.data.totalCategories})`
                    );
                  }
                })
                .catch((err) => {
                  handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                  );
                  clearInterval(interval);
                  globalContext.setLoaderPage(false);
                })
                .finally(() => {
                  waitingMonitorResponse = false;
                });
            }
          }, processCheckIntervalTime);
        } else {
          globalContext.setLoaderPage(false);
        }
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const scrapAmazonCategoryNode = () => {
    const entId = globalContext.currentEnterpriseId;
    const cateId = inputs.categoryToScrap;
    const maxProdCount = inputs.numProdToScrapFromCategory;

    if (!entId) {
      globalContext.showModalWithMsg("La empresa...");
      return;
    } else if (!cateId) {
      globalContext.showModalWithMsg("La categoria...");
      return;
    } else if (!maxProdCount) {
      globalContext.showModalWithMsg("La cantidad de productos...");
      return;
    }

    const processCheckIntervalTime = 5000;
    let processModitorId = 0;
    const headers = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-category-products`,
        {
          headers,
          params: {
            cateId,
            maxProdCount,
          },
        }
      )
      .then((resp) => {
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg(
          "El proceso se esta ejecutando en segundo plano"
        );
      })
      .catch((err) => handleAxiosError(err));
  };

  const updateMlUsers = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-account/update-users`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const fixScrapMl = () => {
    const processCheckIntervalTime = 5000;
    let processModitorId = 0;
    const headers = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/fix-ml`, {
        headers,
      })
      .then((resp) => {
        const data = resp.data.data;

        if (data.monitor) {
          processModitorId = data.monitor;

          //If the process ID was received, start monitoring
          let waitingMonitorResponse = false;
          const interval = setInterval(() => {
            if (!waitingMonitorResponse) {
              waitingMonitorResponse = true;

              axios
                .get(
                  `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                  {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                  }
                )
                .then((resp) => {
                  const data = resp.data.data;

                  if (data.advance < 100) {
                    const advance = data.advance.toFixed(2);
                    globalContext.setLoaderPage(
                      true,
                      `${advance}% (${data.data.currentProd}/${data.data.totalProducts})`
                    );
                  } else {
                    globalContext.setLoaderPage(false);
                    clearInterval(interval);
                    globalContext.showModalWithMsg("Termino");
                  }
                })
                .catch((err) => {
                  handleAxiosError(err, "Disculpe, no se pudo culminar.");
                  clearInterval(interval);
                  globalContext.setLoaderPage(false);
                })
                .finally(() => {
                  waitingMonitorResponse = false;
                });
            }
          }, processCheckIntervalTime);
        } else {
          globalContext.setLoaderPage(false);
        }
      })
      .catch((err) => handleAxiosError(err));
  };

  const syncCurrencies = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-currency/sync`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const syncCountries = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-country/sync`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const startAmazonScrapCron = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/cron/start-scrap-amazon-scraped-products`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  /* useEffect(() => {
        setInterval(() => {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/get-all`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }).then(data => console.log("Respuesta: ", data.data.data)).catch(err => console.error("Fallo: ", err))
        }, 5000)
    }, []); */

  const syncNordServers = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/nord/sync-servers`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const nordVpnUser = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/nordVpn`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const getAmazonScrapConfig = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/config`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        console.log(resp.data);
        const newState = Object.assign({}, inputs);
        newState.amazonScrapConfig = JSON.stringify(
          resp.data.data.config,
          null,
          4
        );
        setInputs(newState);
      })
      .catch((err) => handleAxiosError(err));
  };

  const setAmazonScrapConfig = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/config`,
        {
          config: JSON.parse(inputs.amazonScrapConfig),
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const scrapedVariantToProduct = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/set-scraped-variant-as-product`,
        {},
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const addProxyAcc = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/`,
        {
          brand: "NordVPN",
          enabled: true,
          user: inputs.proxyAccUser,
          maxConn: 6,
          pass: inputs.proxyAccPass,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };
  const sendMlProd = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/ml-post-one-product-from-odoo`,
        {
          dataProd: {
            productId: "618d64c21f6cfbf9db5c6e54",
            title: "TEST DE ACEITUNAS PARA LF",
            price: 15,
            body: "descripcion de prueba",
            condition: "new",
            images: "algo",
            pausePublication: "paused",
            aditionalsParams: {
              brand: "algo",
              model: "test",
              width: "1",
              height: "1",
              length_prod: "1",
              weight: "1",
            },
          },
          entId: "5f0eaeb398c3f413782defb5",
          categoryMl: "61c9eb53a17fb2e14ef98bbc",
          listingType: "gold_special",
          officialStoreId: "933",
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const updateProxyAcc = () => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/${inputs.proxyAccId}`,
        {
          pass: inputs.proxyAccPass,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const getProxyAcc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
        params: {
          pageNumber: 1,
          pageItemCount: 20,
        },
      })
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const disableProxyAcc = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/${inputs.proxyAccId}`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const delNoUrl = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/delete-products-without-url`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const downloadCategoriesAnican = () => {
    globalContext.setLoaderPage(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/anicam/products/list`,
        {
          enterpriseId: "5f0eaeb398c3f413782defb5",
          page: 1,
          limit: 10,
          filter: {
            title: "Estufa",
          },
        },
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        globalContext.setLoaderPage(true);
        console.log("marcas anican", data);
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const testJesus = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/aliexp-prod/${globalContext.currentEnterpriseId}/cat`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        globalContext.setLoaderPage(true);
        console.log("marcas anican", data);
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const downloadodooCategories = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCat`,
        {
          enterpriseId: globalContext.currentEnterpriseId,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };
  const donwloadAttrOdoo = () =>{
    getAttribodoo(globalContext.currentEnterpriseId)
  }

  const removeBackground = () => {
    globalContext.setLoaderPage(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/clean-img/send-list-img-to-clean`,
        {
          listImgsBase64: "any hash on base64",
          enterpriseId: "5f0eaeb398c3f413782defb5",
        },
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        globalContext.setLoaderPage(false);
        console.log("data clean img", data);
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const sendCategoriesWoo = () => {
    globalContext.setLoaderPage(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/create-categories`,
        {
          plugin: "AmazonScrap",
          enterpriseId: "60944575461add5b1957ef8b",
        },
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        globalContext.setLoaderPage(false);
        console.log("data clean img", data);
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const testKeepa = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/clean-prod-keepa/${globalContext.currentEnterpriseId}`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        globalContext.setLoaderPage(false);
        console.log("data keepa", data);
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const lodeml = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/test/update-no-translated`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const freeShipping = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/update-shipping`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const getDataPrice = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/pricesissues/${globalContext.currentEnterpriseId}/${pageReport}/${limitReport}`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };
  const downloadAllImg = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${globalContext.currentEnterpriseId}/download-all-images`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const downloadAllImgVariants = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${globalContext.currentEnterpriseId}/download-all-images-variants`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const downloadAllImgProduct = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${globalContext.currentEnterpriseId}/download-all-images-products`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const getScrapedReport = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/scraped-url-by-acc-type/${dateReportUrl}`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg("Se esjecuta en segundo plano");
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga de los productos."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const getRescrapedReport = () => {
    if (!dateReportUrl) {
      globalContext.showModalWithMsg("La fecha");
      return;
    }

    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/rescraped-by-ent/${dateReportUrl}`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg("Se esjecuta en segundo plano");
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga del reporte."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const getRbacs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
        params: {
          pageNumber: 1,
          pageItemCount: 10,
          filter: {
            free: null,
          },
        },
      })
      .then((resp) => console.log(resp.data))
      .catch((err) => handleAxiosError(err));
  };

  const getRbac = () => {
    const rbacRoute = inputs.rbacRoute;
    const method = inputs.rbacMethod;

    if (!rbacRoute && !method) {
      globalContext.showModalWithMsg("La ruta o el metodo esta vacia.....");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/get-route`,{ route: rbacRoute, method }, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        const data = resp.data.data;
        const newState = Object.assign({}, inputs);
        newState.rbacId = data._id;
        newState.rbacRoute = data.route;
        newState.rbacEnabled = data.enabled;
        newState.rbacFree = data.free;
        newState.rbacMethod = data.method;
        newState.rbacRoles = data.rolesIds.join(",");
        setInputs(newState);
      })
      .catch((err) => handleAxiosError(err));
  };

  const postRbacs = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/rbac/`,
        {
          route: inputs.rbacRoute,
          method: inputs.rbacMethod,
          free: inputs.rbacFree || false,
          enabled: inputs.rbacEnabled || false,
          rolesIds: (inputs.rbacRoles && inputs.rbacRoles.split(",")) || [],
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        globalContext.showModalWithMsg("Registrado");
        const data = resp.data.data.rbac;
        const newState = Object.assign({}, inputs);
        newState.rbacId = data._id;
        newState.rbacRoute = data.route;
        newState.rbacEnabled = data.enabled;
        newState.rbacFree = data.free;
        newState.rbacMethod = data.method;
        newState.rbacRoles = data.rolesIds.join(",");
        setInputs(newState);
      })
      .catch((err) => handleAxiosError(err));
  };

  const putRbac = () => {
    const rbacId = inputs.rbacId;

    if (!rbacId) {
      globalContext.showModalWithMsg("El ID...");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/rbac/${rbacId}`,
        {
          route: inputs.rbacRoute,
          method: inputs.rbacMethod,
          free: inputs.rbacFree || false,
          enabled: inputs.rbacEnabled || false,
          rolesIds: (inputs.rbacRoles && inputs.rbacRoles.split(",")) || [],
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        globalContext.showModalWithMsg("Actualizado");
        const data = resp.data.data.rbac;
        const newState = Object.assign({}, inputs);
        newState.rbacRoute = data.route;
        newState.rbacEnabled = data.enabled;
        newState.rbacFree = data.free;
        newState.rbacMethod = data.method;
        newState.rbacRoles = data.rolesIds.join(",");
        setInputs(newState);
      })
      .catch((err) => handleAxiosError(err));
  };

  const delRbac = () => {
    const rbacId = inputs.rbacId;

    if (!rbacId) {
      globalContext.showModalWithMsg("El ID...");
      return;
    }

    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/${rbacId}`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        console.log(resp.data);
        globalContext.showModalWithMsg("Eliminado");
      })
      .catch((err) => handleAxiosError(err));
  };

  const getDuplicatedReport = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/duplicated-titles`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg("Se esjecuta en segundo plano");
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga del reporte."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const deleteDuplicatedTitle = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/delete-duplicated-titles`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        globalContext.setLoaderPage(false);
        console.log("data: ", data);
      })
      .catch((err) => {
        handleAxiosError(err, "Disculpe, no se pudo culminar.");
        globalContext.setLoaderPage(false);
      });
  };
  const weightToMl = () => {
    const entId = globalContext.currentEnterpriseId;

    if (!entId) {
      globalContext.showModalWithMsg("La empresa...");
      return;
    }
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/weight-for-ml/${globalContext.currentEnterpriseId}`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg("Se esjecuta en segundo plano");
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo culminar la descarga del reporte."
        );
        globalContext.setLoaderPage(false);
      });
  };

  const amazonPublishSimulation = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/simulate-publishing`,
        {
          prodIds: ["638e702c9b766e6b4986e998"],
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => handleAxiosError(err));
  };

  const getNotifProcess = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/notif-process/`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
          params: {
            plugin: "odoo",
            productId: 114065,
            // productTemplateId: 123,
            // companyId: 123,
          }
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
      })
      .catch((err) => handleAxiosError(err, "Error"))
      .finally(() => globalContext.setLoaderPage(false));
  }

  const getApiDebug = () => {
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api-debug/60a59d4689ed925743259a78`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
      })
      .catch((err) => handleAxiosError(err, "Error"))
      .finally(() => globalContext.setLoaderPage(false));
  }

  return (
    <Container>
      {redirect}
      <div className="pt-3 mb-5">
        <h2>Sincronizar</h2>
        <Row>
          <Col md={3}>
            <Button className="w-100" onClick={syncCurrencies}>
              Sicronizar monedas
            </Button>
          </Col>

          <Col md={3}>
            <Button className="w-100" onClick={syncCountries}>
              Sincronizar países{" "}
            </Button>
          </Col>
        </Row>
        <hr/>
      </div>
      <div className="mt-5">
      <Form className="mt-3" direction="vertical" gap={15}>

        <h2>Descargas</h2>
        <div className="mt-4">
          <h5>Odoo</h5>
          <Row
            className="mb-4 d-flex align-items-center"
            style={{ gap: "1rem" }}
          >
            <Col md={3}>
              <Button className="w-100" onClick={() => downloadAllImg()}>
                Descargar todas las imagenes de odoo
              </Button>
            </Col>
            <Col md={3}>
              <Button
                className="w-100"
                onClick={() => downloadAllImgVariants()}
              >
                Descargar todas las imagenes de odoo Variantes
              </Button>
            </Col>
            <Col md={3}>
              <Button className="w-100" onClick={() => downloadAllImgProduct()}>
                Descargar todas las imagenes de odoo Productos
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Button
                className="w-100"
                onClick={() => downloadodooCategories()}
              >
                Descargar Cat odoo
              </Button>
            </Col>
            <Col md={3}>
              <Button
                className="w-100"
                onClick={donwloadAttrOdoo}
              >
                Descargar atributos de Odoo
              </Button>
            </Col>
          </Row>
          <hr/>
        </div>
        <div className="mt-4">
          <h5>Mercado Libre</h5>
          <Row>
            <Col md={6} className="d-flex">
              <Form.Control
                type="text"
                placeholder="País ML"
                name="mlCountry"
                value={inputs.mlCountry || ""}
                onChange={updateInputState}
                className="w-100"
              />
              <Button className="w-100" onClick={downloadCategories} >
                Descargar Categorias ML
              </Button>
            </Col>
            <Col md={2}>
              <Button className="w-100" onClick={weightToMl}>
                weightToMl
              </Button>
            </Col>
            <Col md={2}>
              <Button className="bg-success border-0" onClick={updateMlUsers}>
                Update ML Users
              </Button>
            </Col>
          </Row>
          <hr/>
        </div>
        <div className="mt-4">
          <h5>Amazon</h5>
          <Row style={{ gap: "1rem" }}>
            <Col md={4}>
              <Form.Control
                type="text"
                placeholder="Num Niveles"
                name="numAmazonLevel"
                value={inputs.numAmazonLevel || ""}
                onChange={updateInputState}
                className="w-100"
              />
              <Button onClick={downloadAmazonCategories} className="w-100">
                Descargar Categorias Amazon
              </Button>
            </Col>
            <Col md={8}>
              <div className="d-flex">
                <input
                  size="md"
                  placeholder="Pagina"
                  name="pagina"
                  onChange={(e) => setPageReport(e.target.value)}
                  className="w-100 form-control"
                />
                <input
                  size="sm"
                  placeholder="Limite por paginal"
                  name="total"
                  onChange={(e) => setLimitReport(e.target.value)}
                  className="w-100 form-control"
                />
              </div>
              <Button className="w-100" onClick={getDataPrice}>
                Obtener datos de precios
              </Button>
            </Col>
            <Col md={9} className="flex">
              <div>
                Fecha reporte:
                <DateTime
                  setValue={setDateReportUrl}
                  valueDefaultText="Fecha Desde"
                />
              </div>

              <div className="d-flex" style={{ gap: "2rem" }}>
                <Button className="w-100" onClick={getScrapedReport}>
                  Descargar reporte de scrapeados
                </Button>
                <Button className="w-100" onClick={getRescrapedReport}>
                  Descargar reporte de rescrapeados
                </Button>
              </div>
            </Col>

            <Col md={10} className="d-flex">
              <Form.Control
                type="text"
                placeholder="entWeigth"
                name="entWeigth"
                value={inputs.entWeigth || ""}
                onChange={updateInputState}
                className="w-100"
              />
              <Button className="w-75" onClick={amazonPublishSimulation}>
                Simulación de publicación de Amazon
              </Button>
            </Col>
          </Row>
          <hr/>

        </div>

        <div>
        <h5 className="mt-4">Otros</h5>
          <Row
            className="mb-4 d-flex align-items-center"
            style={{ gap: "1rem" }}
            >
            <Col md={3}>
              <Button
                className="w-100"
                onClick={() => downloadCategoriesAnican()}
              >
                Braands Anican
              </Button>
            </Col>
            <Col md={3}>
              <Button className="w-100" onClick={freeShipping}>
                Envío gratis

              </Button>
            </Col>
            
            
          </Row>
          <Row className="mb-4 d-flex align-items-center">
           
            <Col md={2}>
              <Button
                className="bg-danger border-0"
                onClick={() => removeBackground()}
              >
                Limpiar imagenes
              </Button>
            </Col>
            <Col md={2}>
              <Button className="bg-secondary border-0" onClick={lodeml}>
                Traducir titulos
              </Button>
            </Col>
          </Row>
        </div>
          
        </Form>
        <hr/>
        <hr/>

      </div>

      <div className="pt-3 mb-5">
        <h2>Scrapping</h2>
        <Row style={{ gap: "1rem" }}>
          <Col md={3}>
            <Button className="w-100 bg-secondary border-0" onClick={fixScrapMl}>
              <del>fixScrapMl</del>¿?
            </Button>
          </Col>
          <Col md={3}>
            <Button className="w-100" onClick={startAmazonScrapCron}>
              Start Amazon Scrap Cron
            </Button>
          </Col>
          <Col md={4}>
            <Button className="w-100" onClick={scrapedVariantToProduct}>
              Variante escrapeada a producto
            </Button>
          </Col>
          <Col md={8} className="d-flex flex-column align-items-center">
            <Form.Control
              size="sm"
              as="textarea"
              placeholder="Config"
              name="amazonScrapConfig"
              value={inputs.amazonScrapConfig || ""}
              onChange={updateInputState}
              className="w-100"
            />
            <div
              className="d-flex w-100 justity-content-between"
              style={{ gap: "2rem" }}
            >
              <Button className="w-75" onClick={getAmazonScrapConfig}>
                GetAmazonScrapConfig
              </Button>
              <Button className="w-75" onClick={setAmazonScrapConfig}>
                SetAmazonScrapConfig
              </Button>
            </div>
          </Col>
          <Col md={12}>
            <div className="d-flex justify-content-between ">
              {" "}
              <Form.Control
                type="text"
                placeholder="ID categoria Amazon"
                name="categoryToScrap"
                value={inputs.categoryToScrap || ""}
                onChange={updateInputState}
                className="w-50 mr-2"
              />
              <Form.Control
                type="text"
                placeholder="Cantidad Productos"
                name="numProdToScrapFromCategory"
                value={inputs.numProdToScrapFromCategory || ""}
                onChange={updateInputState}
                className="w-50 me-2"
              />
            </div>
            <Button onClick={scrapAmazonCategoryNode} className="w-100">
              Scrap
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>
            <Button className="bg-danger border-0 w-100" onClick={delNoUrl}>
              Eliminar productos sin url
            </Button>
          </Col>
        </Row>
        <hr/>
      </div>

      <div className="pt-3 mb-5">
        <h2>Nord</h2>
        <Row>
          <Col md={3}>
            <Button className="w-100" onClick={syncNordServers}>Sync Nord Servers</Button>
          </Col>
          <Col md={3}>
            <Button className="w-100" onClick={nordVpnUser}>Smart Proxies</Button>
          </Col>
        </Row>
        <hr/>
      </div>
      <div className="pt-3 mb-5">
        <h2>Cuentas Proxys</h2>
        <Row className="mb-4 d-flex align-items-center" style={{ gap: "1rem" }}>
          <Col md={3}>
            <Button className="w-100" onClick={addProxyAcc}>Añadir cuenta proxy</Button>
          </Col>
          <Col md={3}>
            <Button className="w-100" onClick={updateProxyAcc}>Actualizar cuenta proxy</Button>
          </Col>
          <Col md={3}>
            <Button className="w-100" onClick={getProxyAcc}>Obtener cuenta de proxy</Button>
          </Col>

          <Col md={8} className="">
            <div className="d-flex">
              <Form.Control
                size="sm"
                placeholder="ProxyAcc Mongo ID"
                name="proxyAccId"
                value={inputs.proxyAccId || ""}
                onChange={updateInputState}
                className="form-control"
              />
              <Form.Control
                size="sm"
                placeholder="ProxyAcc User"
                name="proxyAccUser"
                value={inputs.proxyAccUser || ""}
                onChange={updateInputState}
                className="form-control"
              />
              <Form.Control
                size="sm"
                placeholder="ProxyAcc Pass"
                name="proxyAccPass"
                value={inputs.proxyAccPass || ""}
                onChange={updateInputState}
                className="form-control"
              />
            </div>
            <Button className="w-100" onClick={disableProxyAcc}>
              Deshabilitar cuenta proxy
            </Button>
          </Col>
        </Row>
        <hr/>
      </div>

      {/* <Form className="mt-3" direction="vertical" gap={15}>
                <Row className="mb-4 d-flex align-items-center">
                    <Col>
                        <Button onClick={() => downloadAllImg()}>
                            Descargar todas las imagenes de odoo
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => downloadAllImgVariants()}>
                            Descargar todas las imagenes de odoo Variantes
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => downloadAllImgProduct()}>
                            Descargar todas las imagenes de odoo Productos
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-4 d-flex align-items-center">
                    <Col md={3}>
                        <Button onClick={() => testKeepa()}>
                            Test Keepa
                        </Button>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => downloadodooCategories()}>
                            Descargar Cat odoo
                        </Button>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => testJesus()}>sync categories ali</Button>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => sendCategoriesWoo()}>Enviar categorias Woo</Button>
                    </Col>
                </Row>

                <Row className="mb-4 d-flex align-items-center">
                    <Col md={3}>
                        <Button onClick={() => removeBackground()}>Limpiar imagenes</Button>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => downloadCategoriesAnican()}>Braands Anican</Button>
                    </Col>
                    <Col md={3}>

                        <Button onClick={() => sendMlProd()}>Publica ml odoo ML</Button>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            type="text"
                            placeholder="País ML"
                            name="mlCountry"
                            value={inputs.mlCountry || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={downloadCategories} className="w-1">Descargar Categorias ML</Button>
                    </Col>
                    <Col md={12} className="my-4">
                        <Row>
                            <Col md={5} >
                                <Form.Control
                                    type="text"
                                    placeholder="Num Niveles"
                                    name="numAmazonLevel"
                                    value={inputs.numAmazonLevel || ""}
                                    onChange={updateInputState}
                                    className="w-100"
                                />
                                <Button onClick={downloadAmazonCategories} className="w-100">Descargar Categorias Amazon</Button>
                            </Col>
                            <Col md={7}>
                                <div className="d-flex justify-content-between "> <Form.Control
                                    type="text"
                                    placeholder="ID categoria Amazon"
                                    name="categoryToScrap"
                                    value={inputs.categoryToScrap || ""}
                                    onChange={updateInputState}
                                    className="w-50 mr-2"
                                />
                                    <Form.Control
                                        type="text"
                                        placeholder="Cantidad Productos"
                                        name="numProdToScrapFromCategory"
                                        value={inputs.numProdToScrapFromCategory || ""}
                                        onChange={updateInputState}
                                        className="w-50 me-2"
                                    /></div>
                                <Button onClick={scrapAmazonCategoryNode} className="w-100">Scrap</Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row className="mb-4 d-flex align-items-center">
                    <Col md={2}>
                        <Button onClick={updateMlUsers}>Update ML Users</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={fixScrapMl}>fixScrapMl</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={syncCurrencies}>syncCurrencies</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={syncCountries}>syncCountries</Button>
                    </Col>
                </Row>


                <Row className="mb-4 d-flex align-items-center">
                    <Col md={2}>
                        <Button onClick={startAmazonScrapCron}>Start Amazon Scrap Cron</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={syncNordServers}>Sync Nord Servers</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={nordVpnUser}>NordVpnUser</Button>
                    </Col>
                </Row>

                <Row className="mb-4 d-flex align-items-center">
                    <Col md={3}>
                        <Button onClick={getAmazonScrapConfig}>GetAmazonScrapConfig</Button>
                        <Button onClick={setAmazonScrapConfig}>SetAmazonScrapConfig</Button>
                    </Col>

                    <Col md={9}>
                        <Form.Control
                            size="sm"
                            as="textarea"
                            placeholder="Config"
                            name="amazonScrapConfig"
                            value={inputs.amazonScrapConfig || ""}
                            onChange={updateInputState}
                        />
                    </Col>
                </Row>

                <Row className="mb-4 d-flex align-items-center">
                    <Col md={2}>
                        <Button onClick={scrapedVariantToProduct}>scrapedVariantToProduct</Button>
                    </Col>
                </Row>

                <Row className="mb-4 d-flex align-items-center">
                    <Col md={2}>
                        <Button onClick={addProxyAcc}>addProxyAcc</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={updateProxyAcc}>updateProxyAcc</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={getProxyAcc}>getProxyAcc</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={disableProxyAcc}>disableProxyAcc</Button>
                    </Col>
                    <Col md={4}>
                        <Form.Control
                            size="sm"
                            placeholder="ProxyAcc Mongo ID"
                            name="proxyAccId"
                            value={inputs.proxyAccId || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            placeholder="ProxyAcc User"
                            name="proxyAccUser"
                            value={inputs.proxyAccUser || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            placeholder="ProxyAcc Pass"
                            name="proxyAccPass"
                            value={inputs.proxyAccPass || ""}
                            onChange={updateInputState}
                        />
                    </Col>
                </Row>

                <Row className="mb-4 d-flex align-items-center">
                    <Col md={2}>
                        <Button onClick={delNoUrl}>delNoUrl</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={lodeml}>Traducir titulos</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={freeShipping}>Free shipping</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Button onClick={getDataPrice}>getDataPrice</Button>
                    </Col>
                    <Col md={2}>
                        <input
                            size="sm"
                            placeholder="Pagina"
                            name="pagina"
                            onChange={(e) => setPageReport(e.target.value)}
                        />
                    </Col>
                    <Col md={2}>
                        <input
                            size="sm"
                            placeholder="Limite por paginal"
                            name="total"
                            onChange={(e) => setLimitReport(e.target.value)}
                        />
                    </Col>
                </Row>
            </Form> */}
      <div>
        <h2>RBAC</h2>

        <Row className="mb-4 d-flex align-items-center">
          <Col md={5} className="d-flex flex-column" style={{ gap: ".3rem" }}>
            <Form.Control
              size="sm"
              placeholder="Rbac ID"
              name="rbacId"
              value={inputs.rbacId || ""}
              onChange={updateInputState}
            />
            <Form.Control
              size="sm"
              placeholder="Rbac route"
              name="rbacRoute"
              value={inputs.rbacRoute || ""}
              onChange={updateInputState}
            />
            <Form.Switch
              label="Rbac enabled"
              name="rbacEnabled"
              id="rbacEnabled"
              checked={inputs.rbacEnabled || false}
              onChange={updateInputState}
            />
            <Form.Switch
              label="Rbac free"
              name="rbacFree"
              id="rbacFree"
              checked={inputs.rbacFree || false}
              onChange={updateInputState}
            />
            <Form.Control
              size="sm"
              placeholder="Rbac method"
              name="rbacMethod"
              value={inputs.rbacMethod || ""}
              onChange={updateInputState}
            />
            <Form.Control
              as="textarea"
              size="sm"
              placeholder="Rbac roles"
              name="rbacRoles"
              value={inputs.rbacRoles || ""}
              onChange={updateInputState}
            />
          </Col>
          <Col md={3} className="d-flex flex-column" style={{ gap: ".3rem" }}>
            <Button className="w-100" onClick={getRbacs}>
              Obtener todos los RBACS
            </Button>
            <Button className="w-100" onClick={getRbac}>
              Obtener RBAC
            </Button>
            <Button className="w-100" onClick={postRbacs}>
              Publicar RBAC
            </Button>
            <Button className="w-100" onClick={putRbac}>
              Actualizar RBAC
            </Button>
            <Button className="w-100" onClick={delRbac}>
              Eliminar RBAC
            </Button>
          </Col>
        </Row>
      <Row className="mb-4 d-flex align-items-center">
        <Col md={4}>
          <Button onClick={getDuplicatedReport}>Obtener informe duplicado
          </Button>
        </Col>
      </Row>
        <hr/>
      </div>

      <div className="pt-3 mb-5">
        <h2>Otros</h2>
        <Row>
          <Col md={4}>
            <Button className="w-100" onClick={() => testKeepa()}>
              Test Keepa
            </Button>
          </Col>
          <Col md={4}>
            <Button className="w-100" onClick={() => sendCategoriesWoo()}>
              Enviar categorias Woo
            </Button>
          </Col>
          <Col md={3}>
            <Button className="w-100" onClick={() => sendMlProd()}>Publica ml odoo ML</Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>
            <Button className="w-100" onClick={() => getNotifProcess()}>
              getNotifProcess
            </Button>
          </Col>
          <Col md={4}>
            <Button className="w-100" onClick={() => getApiDebug()}>
              getApiDebug
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
