import React, { version } from "react";

export const GlobalContext = React.createContext();

export default class GlobalContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setThird = this.setThird.bind(this);
    this.setParentEntId = this.setParentEntId.bind(this);
    this.setCurrentPageCredential = this.setCurrentPageCredential.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
    this.setModalMsg = this.setModalMsg.bind(this);
    this.showModalWithMsg = this.showModalWithMsg.bind(this);
    this.setCurrentEnterpriseId = this.setCurrentEnterpriseId.bind(this);
    this.reloadNavEnterpriseOptions = this.reloadNavEnterpriseOptions.bind(this);
    this.setLoaderPage = this.setLoaderPage.bind(this);
    this.setShowNotifications = this.setShowNotifications.bind(this);
    this.setShowNotificationsRef = this.setShowNotificationsRef.bind(this);
    this.setShowNotificationsConsole = this.setShowNotificationsConsole.bind(this);
    this.setCodeConsoleNotification = this.setCodeConsoleNotification.bind(this);
    this.setModalVariants = this.setModalVariants.bind(this);
    this.setDataModalVariants = this.setDataModalVariants.bind(this);
    this.setModalPublishProduct = this.setModalPublishProduct.bind(this);
    this.setModalMultiplePost = this.setModalMultiplePost.bind(this);
    this.setModalPublishProductData = this.setModalPublishProductData.bind(this);
    this.setStatusMenu = this.setStatusMenu.bind(this);
    this.setActiveModalLinnk = this.setActiveModalLinnk.bind(this);
    this.setActiveModalLinkCity = this.setActiveModalLinkCity.bind(this);
    this.setCityDataOdoo = this.setCityDataOdoo.bind(this);
    this.setActiveModalMlAm = this.setActiveModalMlAm.bind(this);
    this.setCategorieText = this.setCategorieText.bind(this);
    this.setCityText = this.setCityText.bind(this);
    this.setCategorieId = this.setCategorieId.bind(this);
    this.setCityId = this.setCityId.bind(this);
    this.setCategorieTextRateMlAm = this.setCategorieTextRateMlAm.bind(this);
    this.setCategorieIdRateMlAm = this.setCategorieIdRateMlAm.bind(this);
    this.setActiveModalTranslate = this.setActiveModalTranslate.bind(this);
    this.setModalMultiplePostData = this.setModalMultiplePostData.bind(this);
    this.setOpenModalFormula =  this.setOpenModalFormula.bind(this);
    this.setModalSelectDefaultImg = this.setModalSelectDefaultImg.bind(this);
    this.setOpenInfoIcons = this.setOpenInfoIcons.bind(this);
    this.setOpenAddProductFtp = this.setOpenAddProductFtp.bind(this);
    this.setOpenAddProductFtpId = this.setOpenAddProductFtpId.bind(this);
    this.setSkuByRang = this.setSkuByRang.bind(this);
    this.setOpenModalTracking = this.setOpenModalTracking.bind(this);
    this.setStatusTracking = this.setStatusTracking.bind(this);
    this.setNroTracking = this.setNroTracking.bind(this);
    this.setIdCategoryFind = this.setIdCategoryFind.bind(this);
    this.setIdCategoryFindText = this.setIdCategoryFindText.bind(this);
    this.setActiveFiltertCategory = this.setActiveFiltertCategory.bind(this);
    this.setActiveModalProccess = this.setActiveModalProccess.bind(this);
    this.setActiveModalPublicOtherStore = this.setActiveModalPublicOtherStore.bind(this);
    this.setItemsPublicOtherStore = this.setItemsPublicOtherStore.bind(this);
    this.setItemsToUpddateAndPublish = this.setItemsToUpddateAndPublish.bind(this);
    this.setVerifyLogin = this.setVerifyLogin.bind(this);
    this.setLinkSections = this.setLinkSections.bind(this);
    this.setLinkSucursal = this.setLinkSucursal.bind(this);
    this.setModalStoreIdAndSection = this.setModalStoreIdAndSection.bind(this);
    this.setModalLinkOneProduct = this.setModalLinkOneProduct.bind(this);
    this.setidProductLink = this.setidProductLink.bind(this);
    this.setModalPostProduct = this.setModalPostProduct.bind(this);
    this.setListProductsToSend = this.setListProductsToSend.bind(this);
    this.setLisCategories = this.setLisCategories.bind(this);
    this.setModalFilterOrdes = this.setModalFilterOrdes.bind(this);
    this.setDateFilterOrder = this.setDateFilterOrder.bind(this);
    this.setStatusFilterOrder = this.setStatusFilterOrder.bind(this);
    this.setOrderIdFilterOrder = this.setOrderIdFilterOrder.bind(this);
    this.setModalReporPostProducts = this.setModalReporPostProducts.bind(this);
    this.setModalOrdenOtherMarketplace = this.setModalOrdenOtherMarketplace.bind(this);
    this.setPluginSearchForpost = this.setPluginSearchForpost.bind(this);
    this.setTestFiltres = this.setTestFiltres.bind(this);
    this.state = {
      verifyLogin: false,
      setVerifyLogin: this.setVerifyLogin,
      //Mdal
      modal: {
        show: false,
        msg: "",
        handleClose: this.handleCloseModal,
      },
      setModalMsg: this.setModalMsg,
      setShowModal: this.setShowModal,
      showModalWithMsg: this.showModalWithMsg,

      //User
      user: {
        username: "",
        email: "",
        hasPluginActivated: {
          odoo: false,
          ml: false,
        },
        mainPlugin: null,
      },
      setUserData: this.setUserData,

      //Enterprise
      currentEnterpriseId: "",
      navEnterpriseOptionsRefresh: 0,
      setCurrentEnterpriseId: this.setCurrentEnterpriseId,
      reloadNavEnterpriseOptions: this.reloadNavEnterpriseOptions,

      currentPageCredential: "",
      setCurrentPageCredential: this.setCurrentPageCredential,

      parentEntId: null,
      setParentEntId: this.setParentEntId,


      // third
      third: null,
      setThird: this.setThird,


      loaderPage: false,
      loaderPageText: "",
      setLoaderPage: this.setLoaderPage,
      showNotifications : false,
      setShowNotifications: this.setShowNotifications,
      setShowNotificationsRef: this.setShowNotificationsRef,

      showNotificationsConsole: false,
      setShowNotificationsConsole: this.setShowNotificationsConsole,
      codeConsoleNotification: "",
      setCodeConsoleNotification: this.setCodeConsoleNotification,
      /**
       * ? variants
       */
      dataModalVariants: [],
      modalVariants: false,
      setDataModalVariants: this.setDataModalVariants,
      setModalVariants: this.setModalVariants,

      /**
       * ! publicar producto modal
       */
      modalPublishProductData: null,
      idModalProductModal: null,
      setModalPublishProductData: this.setModalPublishProductData,
      modalPublishProduct: false,
      setModalPublishProduct: this.setModalPublishProduct,

      modalMultiplePost: false,
      setModalMultiplePost: this.setModalMultiplePost,
      modalMultiplePostData: null,
      idModalMultiplePostData: null,
      setModalMultiplePostData: this.setModalMultiplePostData,

      /**
       * ? another menu
       */
      statusMenu: false,
      setStatusMenu: this.setStatusMenu,

      /**
       * categories modal values
       */
      activeModalLinnk: false,
      activeModalLinkCity: false,
      cityDataOdoo: {},
      activeModalMlAm : false,
      activeModalTranslate: false,
      categorieText: '',
      cityText: '',
      categorieId: '',
      cityId: '',
      categorieTextMlAm: '',
      categorieIdMlAm: '',
      /**
       * categories modal seters
       */
      setActiveModalTranslate: this.setActiveModalTranslate, 
      setActiveModalLinnk: this.setActiveModalLinnk,
      setActiveModalLinkCity: this.setActiveModalLinkCity,
      setCityDataOdoo: this.setCityDataOdoo,
      setActiveModalMlAm: this.setActiveModalMlAm,
      setCategorieText: this.setCategorieText,
      setCityText: this.setCityText,
      setCategorieId: this.setCategorieId,
      setCityId: this.setCityId,
      setCategorieTextRateMlAm: this.setCategorieTextRateMlAm,
      setCategorieIdRateMlAm: this.setCategorieIdRateMlAm,

      openModalFormula: false,
      setOpenModalFormula: this.setOpenModalFormula,
      modalSelectDefaultImg : false,
      setModalSelectDefaultImg : this.setModalSelectDefaultImg,
      openIcont: false,
      setOpenInfoIcons: this.setOpenInfoIcons,
      addProductFtp: false,
      addProductFtpId: false,
      setOpenAddProductFtp: this.setOpenAddProductFtp,
      setOpenAddProductFtpId: this.setOpenAddProductFtpId,
      skuByRang: '',
      setSkuByRang: this.setSkuByRang,

      openModalTracking: false,
      setOpenModalTracking: this.setOpenModalTracking,
      statusTracking: [],
      setStatusTracking: this.setStatusTracking,
      nroTracking: null,
      setNroTracking: this.setNroTracking,
      idCategoryFind: '',
      setIdCategoryFind: this.setIdCategoryFind,
      idCategoryFindText: '',
      setIdCategoryFindText: this.setIdCategoryFindText,
      activeFiltertCategory: true,
      setActiveFiltertCategory: this.setActiveFiltertCategory,
      activeModalProcces: false,
      setActiveModalProccess: this.setActiveModalProccess,
      activeModalPublicOtherStore: false,
      setActiveModalPublicOtherStore: this.setActiveModalPublicOtherStore,
      itemsPublicOtherStore: null,
      setItemsPublicOtherStore: this.setItemsPublicOtherStore,
      itemsToUpddateAndPublish: null,
      setItemsToUpddateAndPublish: this.setItemsToUpddateAndPublish,
      linkSections: false,
      setLinkSections: this.setLinkSections,
      linkRestaurants: false,
      setLinkSucursal: this.setLinkSucursal,
      modalStoreAndSection: false,
      setModalStoreIdAndSection: this.setModalStoreIdAndSection,
      modalLinkOneProduct: false,
      setModalLinkOneProduct: this.setModalLinkOneProduct,
      idProductLink: null,
      setidProductLink: this.setidProductLink,
      setModalPostProduct: this.setModalPostProduct,
      modalPostProduct: false,
      setListProductsToSend: this.setListProductsToSend,
      listProductsToSend: [],
      setLisCategories: this.setLisCategories,
      listCategories: false,
      setModalFilterOrdes: this.setModalFilterOrdes,
      modalFilterOrdes: false,
      setDateFilterOrder: this.setDateFilterOrder,
      setStatusFilterOrder: this.setStatusFilterOrder,
      setOrderIdFilterOrder: this.setOrderIdFilterOrder,
      dateFilterOrder: null,
      statusFilterOrder: null,
      orderIdFilterOrder: null,
      setModalReporPostProducts: this.setModalReporPostProducts,
      modalReporPostProducts: false,
      modalOrdenOtherMarketplace: false,
      setModalOrdenOtherMarketplace: this.setModalOrdenOtherMarketplace,
      pluginSearchForpost: '',
      setPluginSearchForpost: this.setPluginSearchForpost,
      setTestFiltres: this.setTestFiltres,
      testFiltres: false
    };
  }
  setTestFiltres(v) {
    this.setState({
      testFiltres: v
    })
  }

  setPluginSearchForpost(v) {
    this.setState({
      pluginSearchForpost: v
    })
  }

  setModalOrdenOtherMarketplace(v) {
    this.setState({
      modalOrdenOtherMarketplace: v
    })
  }

  setModalReporPostProducts(v) {
    this.setState({
      modalReporPostProducts: v
    })
  }

  setDateFilterOrder(v) {
    this.setState({
      dateFilterOrder: v
    })
  }
  setStatusFilterOrder(v) {
    this.setState({
      statusFilterOrder: v
    })
  }
  setOrderIdFilterOrder(v) {
    this.setState({
      orderIdFilterOrder: v
    })
  }

  setModalFilterOrdes(v) {
    this.setState({
      modalFilterOrdes: v
    })
  }
  setLisCategories(v) {
    this.setState({
      listCategories: v
    })
  }
  
  setListProductsToSend(v) {
    this.setState({
      listProductsToSend: v
  })
}
  setModalPostProduct(v) {
    console.log(v);
    this.setState({
      modalPostProduct: v
  })
}
  
  setidProductLink(v) {
    this.setState({
      idProductLink: v
  })
}
  setModalLinkOneProduct(v) {
    this.setState({
      modalLinkOneProduct: v
  })
}
  setModalStoreIdAndSection(v) {
    this.setState({
      modalStoreAndSection: v
  })
}
  setLinkSucursal(v) {
    this.setState({
      linkRestaurants: v
  })
}
  setLinkSections(v) {
    this.setState({
      linkSections: v
  })
}

  setVerifyLogin(v) {
    this.setState({
      verifyLogin: v
    })
  }
  setItemsToUpddateAndPublish(v){
    this.setState({
      itemsToUpddateAndPublish: version
    });
  }
  setItemsPublicOtherStore(v){
    this.setState({
      itemsPublicOtherStore: v
    })
  }
  setActiveModalPublicOtherStore(v){
    this.setState({
      activeModalPublicOtherStore: v
    })
  }
  setActiveModalProccess(v){
    this.setState({
      activeModalProcces: v
    })
  }

  setIdCategoryFindText(v) {
    this.setState({
      idCategoryFindText: v
    })
  }

  setActiveFiltertCategory(v) {
    this.setState({
      activeFiltertCategory: v
    })
  }

  setIdCategoryFind(v) {
    this.setState({
      idCategoryFind: v
    })
  }
  setNroTracking(v) {
    this.setState({
      nroTracking: v
    })
  }
  setStatusTracking(v) {
    this.setState({
      statusTracking: v
    })
  }
  setOpenModalTracking(v) {
    this.setState({
      openModalTracking: v
    })
  }

  setSkuByRang(v) {
    this.setState({
      skuByRang: v
    })
  }
  /**
   * Cambia los datos del usuario
   * @param {Object} data Los datos a asignar
   */
  setUserData(data) {
    const currentState = this.state.user;

    this.setState({
      user: { ...currentState, ...data },
    });
  }

  setDataModalVariants(obj) {
    this.setState({
      dataModalVariants: [obj]
    })
  }
  setModalVariants(v) {
    this.setState({
      modalVariants: v
    })
  }

  setModalPublishProduct(v) {
    this.setState({
      modalPublishProduct: v
    })
  }

  setModalMultiplePost(v) {
    this.setState({
      modalMultiplePost: v
    })
  }

  setModalPublishProductData(obj, id) {
    this.setState({
      modalPublishProductData: obj,
      idModalProductModal: id
    })
  }

  setModalMultiplePostData(obj, id) {
    this.setState({
      modalMultiplePostData: obj,
      idModalMultiplePostData: id
    })
  }

  /**
   * Shows/hides the modal
   * @param {boolean} show
   */
  setShowModal(show) {
    const newState = this.state.modal;
    newState.show = show;

    this.setState({ modal: newState });
  }

  /**
   * Updates the modal message
   */
  setModalMsg(msg) {
    const newState = this.state.modal;
    newState.msg = msg;

    this.setState({ modal: newState });
  }

  setModalSelectDefaultImg(v) {
    this.setState({modalSelectDefaultImg: v})
  }

  setOpenInfoIcons(v) {
    this.setState({openIcont: v})
  }
  setOpenAddProductFtp(v) {
    this.setState({addProductFtp: v})
  }
  setOpenAddProductFtpId(v) {
    this.setState({addProductFtpId: v})
  }

  /**
   * Show the modal and fill it with a message
   * @param {string} msg The message
   * @param {string} title The title
   * @param {Object} opt The modal config
   */
  showModalWithMsg(msg, title="", opt={}) {
    const newState = this.state.modal;
    const msgParts = msg.split && msg.split("\n") || [msg];
    const newMsg = (<div>
      { msgParts.shift() }
      { msgParts.map(m => (<span><br/>{m}</span>)) }
    </div>);

    newState.msg = newMsg;
    newState.title = title;
    newState.show = true;
    newState.config = opt;

    this.setState({ modal: newState });
  }

  /**
   * Close the modal
   */
  handleCloseModal() {
    this.setShowModal(false);
  }
  setOpenModalFormula() {
    this.setState({openModalFormula: !this.state.openModalFormula})
  }

  /**
   * Sets the current enterprise ID
   * @param {string} currentEnterpriseId
   */
  setCurrentEnterpriseId(currentEnterpriseId) {
    this.setState({ currentEnterpriseId });
  }

  /**
   * Sets the current Credential ID
   * @param {string} currentPageCredential
   */
  setCurrentPageCredential(currentPageCredential) {
    this.setState({ currentPageCredential });
  }

  /**
   * Set the current Parent Enterprise ID 
   * @param {string} parentEntId 
   */
  setParentEntId(parentEntId) {
    this.setState({ parentEntId });
  }

  /**
   * Set the third user status
   * @param {boolean} third 
   */
  setThird(third) {
    this.setState({ third });
  }


  /**
   * Force enterprise dropdown reload
   */
  reloadNavEnterpriseOptions() {
    this.setState({ navEnterpriseOptionsRefresh: this.state.navEnterpriseOptionsRefresh +1 });
  }

  /**
   *
   * @param {boolean} loaderPage
   */
  setLoaderPage(loaderPage, loaderPageText="") {
    this.setState({ loaderPage, loaderPageText });
  }
  setShowNotifications() {
    this.setState({showNotifications : !this.state.showNotifications})
  }
  setShowNotificationsRef() {
    this.setState({showNotifications: false})
  }

  setShowNotificationsConsole() {
    this.setState({showNotificationsConsole: !this.state.showNotificationsConsole});
  }
  setCodeConsoleNotification(valorConsole) {
    this.setState({codeConsoleNotification: valorConsole})
  }
  /**
   * ! another menu func
   */
  setStatusMenu(v) {
    this.setState({statusMenu: v})
  }

  /**
   * categories seters
   */
  setActiveModalLinnk(v) {
    this.setState({activeModalLinnk : v})
  }
  setActiveModalLinkCity(v) {
    this.setState({activeModalLinkCity: v})
  }
  setActiveModalMlAm(v) {
    this.setState({activeModalMlAm: v})
  }
  setActiveModalTranslate(v) {
    this.setState({activeModalTranslate: v})
  }
  setCategorieText(v) {
    this.setState({categorieText : v})
  }
  setCityText(v) {
    this.setState({cityText: v})
  }
  setCategorieId(v) {
    this.setState({categorieId : v})
  }
  setCityId(v) {
    this.setState({cityId: v})
  }
  setCategorieTextRateMlAm(v) {
    this.setState({categorieTextMlAm: v})
  }
  setCategorieIdRateMlAm(v) {
    this.setState({categorieIdMlAm: v})
  }
  setCityDataOdoo(v) {
    this.setState({cityDataOdoo: v})
  }
  /**
   * Renderiza el componente
   */
  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
