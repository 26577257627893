import React, { useContext, useState, useEffect } from "react";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";

//* metodo que imprime el step
import PrintStep from "./PrintSteps";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";


export default function OdooConfig() {
  const [redirect, setRedirect] = useState(null);
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 45);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
  }, []);
  //[globalContext.parentEntId]);

  /**
   * Sync the pricelist data
   */
  const syncPriceLists = () => {  // Function to synchronize price list data
    const entId = globalContext.currentEnterpriseId;  // Get the current enterprise ID from the global context
    if (!entId) return;  // If there's no enterprise ID, exit the function

    // Ask for confirmation before proceeding with the price list download
    if (!window.confirm(`¿Desea descargar los datos de sus tarifas?`)) return;

    globalContext.setLoaderPage(true);  // Show loading indicator

    // Send a GET request to fetch the price list data
    axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}/sync`,
      {
        cancelToken: axiosCancelTokenSource.token,  // Use a cancel token to handle request cancellation
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,  // API key for authentication
          "x-access-token": localStorage.getItem(keyAccessToken),  // User access token from local storage
          "x-user-id": localStorage.getItem(keyUserId),  // User ID from local storage
        },
      }
    ).then(resp => {
      // Show a success message when the data is successfully downloaded
      globalContext.showModalWithMsg(
        "Los datos fueron descargados."
      );
    }).catch((err) => {
      if (axios.isCancel(err)) return; // Ignore axios cancel errors

      // Show an error message if the request fails
      globalContext.showModalWithMsg(
        "Disculpe, hubo un error al intentar descargar los datos."
      );
      console.error(err, err.response);
    }).finally(() => {
      globalContext.setLoaderPage(false);  // Hide loading indicator after request completion
    });
}


  /**
   * Sync the taxes lists data
   */
  const syncTaxLists = () => {  // Function to synchronize tax data
    const entId = globalContext.currentEnterpriseId;  // Get the current enterprise ID from the global context
    if (!entId) return;  // If there's no enterprise ID, exit the function

    // Ask for confirmation before proceeding with the tax data download
    if (!window.confirm(`¿Desea descargar los datos de sus impuestos?`)) return;

    globalContext.setLoaderPage(true);  // Show loading indicator

    // Send a GET request to synchronize tax data
    axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-tax/${entId}/sync`,
      {
        cancelToken: axiosCancelTokenSource.token,  // Use a cancel token to handle request cancellation
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,  // API key for authentication
          "x-access-token": localStorage.getItem(keyAccessToken),  // User access token from local storage
          "x-user-id": localStorage.getItem(keyUserId),  // User ID from local storage
        },
      }
    ).then(resp => {
      // Show a success message when the data is successfully downloaded
      globalContext.showModalWithMsg(
        "Los datos fueron descargados."
      );
    }).catch((err) => {
      if (axios.isCancel(err)) return; // Ignore axios cancel errors

      // Show an error message if the request fails
      globalContext.showModalWithMsg(
        "Disculpe, hubo un error al intentar descargar los datos."
      );
      console.error(err, err.response);
    }).finally(() => {
      globalContext.setLoaderPage(false);  // Hide loading indicator after request completion
    });
}


  /**
   * Donwload archived products odoo
   */
  const donwloadArchiveds = () => {  // Function to download archived products
    const entId = globalContext.currentEnterpriseId;  // Get the current enterprise ID from the global context
    if (!entId) return;  // If there's no enterprise ID, exit the function

    // Ask for confirmation before proceeding with the download
    if (!window.confirm(`¿Desea descargar los datos de los productos archivados?`)) return;

    globalContext.setLoaderPage(true);  // Show loading indicator

    // Send a POST request to download archived products
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/archivedProducts`,{}, 
      {
        cancelToken: axiosCancelTokenSource.token,  // Use a cancel token to handle request cancellation
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,  // API key for authentication
          "x-access-token": localStorage.getItem(keyAccessToken),  // User access token from local storage
          "x-user-id": localStorage.getItem(keyUserId),  // User ID from local storage
        },
      }
    ).then(resp => {
      // Show a success message when the data is downloaded
      globalContext.showModalWithMsg(
        "The data has been successfully downloaded."
      );
    }).catch((err) => {
      if (axios.isCancel(err)) return; // Ignore axios cancel errors

      // Show an error message if the download fails
      globalContext.showModalWithMsg(
        "Disculpe, hubo un error al intentar descargar los datos."
      );
      console.error(err, err.response);
    }).finally(() => {
      globalContext.setLoaderPage(false);  // Hide loading indicator after request completion
    });
}

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  return (
    <OdooStyle>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <Link to="/admin/plugins/my-plugins/my-menu-plugins">
          <button className="btn-back">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
      </div>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-center"
        >
          <div className="odooFormBody">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="hearderFormOdoo"
                >
                  <p> Rellene los Siguientes Campos para el plugin de Odoo</p>
                </Col>
              </Row>
              <PrintStep />
            </Container>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="footerFormOdoo" style={{gap: 30}}>
          <button className="download-tarifa" onClick={syncPriceLists} style={{marginBottom: 0}}>
            Descargar tarifas
          </button>
          <button className="download-tarifa" onClick={syncTaxLists} style={{marginBottom: 0}}>
            Descargar impuestos
          </button>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="footerFormOdoo" style={{gap: 30, marginTop: 20}}>
          <button className="download-tarifa" onClick={donwloadArchiveds}>
            Descargar archivados
          </button>
        </Col>
      </Row>
    </OdooStyle>
  );
}
