import styled from "styled-components";

const Cont = styled.div`
    width: 80%;
    padding: 20px 10px;
    margin: 0 auto;
`;

const SubCont = styled.div`
    width: 80%;
    background-color: #FFF;
    padding: 20px 10px;
    margin: 0 auto;

    border-radius: 0.428rem;
    border: 1px solid #f0f0f0;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`;

const ContForm = styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    padding: 20px; 
    padding-bottom: ${props => (props.padding_bottom ? '60px' : '20px')};
    margin-bottom: ${props => (props.padding_bottom ? '100px' : '0px')};
`;
const ContForm2 = styled.form`
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 20px; 
    padding: 20px; 
    padding-bottom: ${props => (props.padding_bottom ? '60px' : '20px')};
    margin-bottom: ${props => (props.padding_bottom ? '100px' : '0px')};
`;

const Label = styled.label`
    color: #707070;
    font-size: 12pt;
    margin-left: 0;
    margin-right: 0;
    font-weight: lighter;
    margin-top: .2857rem;
    margin-bottom: .2857rem;
    width: 100%;
`;


const Input = styled.input`
    display: block;
    width: 100%;
    height: 38px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        border-color: #0000ff; // Cambia el color del borde al enfocar
        box-shadow: 0 0 5px rgba(115, 103, 240, 0.5); // Añade una sombra al enfocar
        outline: none; // Elimina el contorno por defecto
    }

    &::placeholder {
        color:#d8d6de; 
        font-weight: 550;
        opacity: 1; 
    }
`;

const SubTitle = styled.h2`
    grid-column: 1 / -1;
    color: #898989;
    font-size: 14pt;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
    margin-top: .2857rem;
    margin-bottom: .2857rem;
    width: 100%;
`;

const Title = styled.h1`
    color: #898989;
    font-size: 16pt;
    font-weight: 500;
    margin-bottom: 30px;
    width: 100%;
`;



const ContFormButton = styled.div`
    grid-column: 1 / -1;
    width: 100%;
`;

const TitleReport = styled.h1`
    grid-column: 1 / -1;
    width: 100%;
`;

const ContEnterprisesSelect = styled.div`
    margin-bottom: 15px;
`;

const Button = styled.button`
    padding: 6px 16px; /* Espaciado interno */
    border-radius: 0.25rem; /* Esquinas redondeadas */
    outline: none; /* Elimina el contorno por defecto */
    background: #7367F0; /* Color de fondo */
    color: #FFFFFF; /* Color del texto */
    border: none; /* Sin borde */
    cursor: pointer; /* Cambia el cursor al pasar sobre el elemento */
    transition: all ease-in-out 0.2s; /* Transición suave para todos los cambios */
    
    &:hover {
        background: #5a54d1; 
    }

`;

const customStyles = {
    control: (base,state) => ({
        ...base,
        width: '100%',
        height: '30px',
        padding: '2px',
        borderColor: state.isFocused ? '#7367f0' : '#d8d6de', // Color del borde cuando está enfocado
        boxShadow: state.isFocused ? '0 0 5px rgba(115, 103, 240, 0.5)' : 'none', // Sombra al enfocar
        /*'&:hover': {
            borderColor: '#0000ff', // Color del borde al pasar el ratón
        },*/
    }),
    menu: (base) => ({
        ...base,
        maxHeight: '150px', // Altura máxima del menú
        overflowY: 'auto', // Permite el desplazamiento vertical
    }),
    menuList: (base) => ({
        ...base,
        maxHeight: '150px', // Altura máxima de la lista de menú
        overflowY: 'auto', // Permite el desplazamiento vertical
    }),
    option: (base, state) => ({
        ...base,
        color: state.isSelected ? '#fff' : '#333', // Color del texto
        backgroundColor: state.isSelected ? '#7367f0' : state.isFocused ? '#f0f0f0' : '#fff', // Color de fondo
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: state.isSelected ? '#7367f0' : '#e0e0e0', // Color al pasar el ratón por encima
        },
    }),
};

const BtnShowPass = styled.button`
    position: absolute;
    transition: 0.3s;
    width: 30px;
    height: 30px;
    outline: none !important;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    color: #707070;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const RC_Style = {
    Cont,
    SubCont,
    ContForm,
    ContForm2,
    Label,
    Input,
    Title,
    SubTitle,
    ContFormButton,
    TitleReport,
    ContEnterprisesSelect,
    Button,
customStyles,
    BtnShowPass
};

export default RC_Style;