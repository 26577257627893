import React, {useState, useEffect, useContext} from 'react'
import RC_Style from '../../../../../assets/css/components/admin/plugins/reportesComercialesStyle';
import SelectEnterprise from '../components/SelectEnterprise';
import SubmitButton from '../components/SubmitButton';
import SelectRate from '../components/SelectRate';
import SelectReport from '../components/SelectReport';
import useRcReport from '../functions/useRcReport';
import { BiHide, BiShow } from "react-icons/bi";
import Select from 'react-select';

export default function ReportesComerciales() {

    const { getInventoryReport, getPriceReport} = useRcReport();

    const [login, setLogin] = useState(false);

    const [usuario, setUsuario] = useState('');
    const [userIsReadOnly, setUserIsReadOnly] = useState(false);
    const [clave, setClave] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [passwordIsReadOnly, setPasswordIsReadOnly] = useState(false);
    const [reporteSeleccionado, setReporteSeleccionado] = useState('');
    const [reportIsReadOnly, setReportIsReadOnly] = useState(false);
    const [blockId, setBlockId] = useState('')

    const [enterprise, setEnterprise] = useState('');
    const [enterprisesData, setEnterprisesData] = useState([]);
    const [loadingEnterprisesData, setLoadingEnterprisesData] = useState(false);
    const [errorLoadingEnterprisesData, setErrorLoadingEnterprisesData] = useState(false);

    const [mainRate, setMainRate] = useState();
    const [promotionalRate, setPromotionalRate] = useState();
    const [ratesData, setRatesData] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    
    const [pvpRate, setPvpRate] = useState(null);
    const [loadingRatesData, setLoadingRatesData] = useState(false);
    const [errorLoadingRatesData, setErrorLoadingRatesData] = useState(false);

    const [blockedInventoryIds, setBlockedInventoryIds] = useState([])
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass);
    };

    const addIdsToBlock = (e) =>{
        e.preventDefault()
        if(!blockId.length) return
        setBlockedInventoryIds(prevIds => [...prevIds, blockId])
        setBlockId('')
        console.log(blockedInventoryIds, 'eee')
    }

    const removeIdToBlock = (idToRemove) => {
        setBlockedInventoryIds(prevIds => prevIds.filter(id => id !== idToRemove));
    };
    //Handler para procesar los reportes
    const handleSubmitGetReport = (e) => {
        e.preventDefault();
        
        switch (reporteSeleccionado) {
            case 'reporte-inventario':
                getInventoryReport(usuario, clave, enterprise, false );
                break;
            case 'reporte-precios':
                console.log(ratesData, 'data xdxdxdxd datadatadata')
                let mainRateForReport = ratesData.odooPricelist.find(rt => rt.id === mainRate.value);
                getPriceReport(usuario, clave, enterprise, mainRateForReport, promotionalRate.value, pvpRate, blockedInventoryIds, false, false, brands, categories); 
                break;
            default:
                console.log('Seleccione un reporte válido.');
        }
    };

    //Handler para loguearse con las credenciales de odoo
    const handleSubmitLogin = async (e) => {
        e.preventDefault();

        if(!login){ 
            //Procede a loguearse
            switch (reporteSeleccionado) {
                case 'reporte-inventario':
                    try {
                        const data = await getInventoryReport(usuario, clave, enterprise, true );
                        if(data){
                            setEnterprisesData(data);
                            setLoadingEnterprisesData(false);
                            setLogin(true);
                            setUserIsReadOnly(true);
                            setPasswordIsReadOnly(true);
                            setReportIsReadOnly(true);
                        }
                        
                    } catch (error) {
                        console.error("Error fetching enterprises:", error);
                        setLoadingEnterprisesData(false);
                        setErrorLoadingEnterprisesData(true);
                    }
                    break;
                case 'reporte-precios':
                    try {
                        const data = await getPriceReport(usuario, clave, enterprise, mainRate, promotionalRate, pvpRate, blockedInventoryIds, true, false);
                        if(data){ 
                            setEnterprisesData(data);
                            setLoadingEnterprisesData(false);
                            setLogin(true);
                            setUserIsReadOnly(true);
                            setPasswordIsReadOnly(true);
                            setReportIsReadOnly(true);
                        }
                    } catch (error) {
                        console.error("Error fetching enterprises:", error);
                        setLoadingEnterprisesData(false);
                        setErrorLoadingEnterprisesData(true);
                    }
                    break;
                default:
                    console.log('Seleccione un reporte válido.');
            }
        }
        else{   
            //Reiniciar parametros y credenciales
            setLogin(false);
            setPasswordIsReadOnly(false);
            setUserIsReadOnly(false);
            setReportIsReadOnly(false);
            setEnterprise('');
            setMainRate();
            setPromotionalRate();
            setEnterprisesData();
            setCategories()
            setBrands()
            setLoadingEnterprisesData(false);
            setErrorLoadingEnterprisesData(false);
            setRatesData([]);
            setLoadingRatesData(false);
            setErrorLoadingRatesData(false);
        }
        
    };


    //Cada vez que se actualice la empresa se actualizan las tarifas
    useEffect(() => {
 
        const fetchRates = async () => {
            try {
                if(enterprise){
                    const data = await getPriceReport(usuario, clave, enterprise, mainRate, promotionalRate, pvpRate, blockedInventoryIds, false, true);
                    console.log('la data devuelta de back is not fake', data)
                    if(data){
                        setRatesData(data);
                        setLoadingRatesData(false);
                        setErrorLoadingRatesData(false);
                    }
                    else{
                        setLoadingRatesData(false);
                        setErrorLoadingRatesData(true);
                    }
                    
                }
                else{
                    setLoadingRatesData(false);
                    setErrorLoadingRatesData(true);
                }
                
            } catch (error) {
                console.error("Error fetching enterprises:", error);
                setLoadingRatesData(false);
                setErrorLoadingRatesData(true);
            }
        };

        if(reporteSeleccionado == 'reporte-precios'){
            //Se cargan las tarifas cuando es un reporte de precios
            setLoadingRatesData(true);
            fetchRates();
        }
        

    }, [enterprise]);

    const containerStyle = {
        position: 'relative', 
    };
    
    
    return (
        <RC_Style.Cont>
            <RC_Style.Title>Reportes Comerciales</RC_Style.Title>
            <RC_Style.SubCont>
                <RC_Style.SubTitle>Ingrese los datos para generar sus reportes</RC_Style.SubTitle>
                <RC_Style.ContForm onSubmit={handleSubmitLogin}>

                    <div style={{ marginBottom: '15px' }}>
                        <RC_Style.Label htmlFor="usuario">Usuario de Odoo</RC_Style.Label>
                        <RC_Style.Input
                            type="text"
                            id="usuario"
                            value={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            placeholder="Usuario de Odoo"
                            required
                            readOnly={userIsReadOnly}
                        />
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <RC_Style.Label htmlFor="clave">Clave de Odoo</RC_Style.Label>
                        <div style={containerStyle}>
                            <RC_Style.Input
                                //type="password"
                                type={showPass ? "text" : "password"}
                                id="clave"
                                value={clave}
                                onChange={(e) => setClave(e.target.value)}
                                placeholder="Clave de Odoo"
                                required
                                readOnly={passwordIsReadOnly}
                            />

                            <RC_Style.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                {showPass ? <BiHide /> : <BiShow />}
                            </RC_Style.BtnShowPass>
                        </div>
                        
                        
                    </div>
                        
                    
                    <SelectReport reporteSeleccionado={reporteSeleccionado} setReporteSeleccionado ={setReporteSeleccionado} isReadOnly={reportIsReadOnly}/>

                    {!login ? <SubmitButton label={'Login'}/> : <SubmitButton label={'Restart'}/>}

                </RC_Style.ContForm>

                {login && 
                    <>
                        {reporteSeleccionado === 'reporte-inventario' && 
                            <>

                                <RC_Style.SubTitle>Generar reporte de inventario</RC_Style.SubTitle>

                                <RC_Style.ContForm onSubmit={handleSubmitGetReport} padding_bottom={true}>
                                    {loadingEnterprisesData && <p>Cargando las empresas...</p>} 
                                    {!loadingEnterprisesData && !errorLoadingEnterprisesData && (
                                        <SelectEnterprise setEnterprise={setEnterprise} enterprise={enterprise} enterprises={enterprisesData}/>
                                    )}
                                    {!loadingEnterprisesData && errorLoadingEnterprisesData && <p>Ocurrio un error cargando las empresas</p>} 
                                    
                                    {enterprise ? (
                                        <SubmitButton label={'Generar reporte'} />
                                    ) : null}
                                </RC_Style.ContForm>

                            </>
                        }

                        {reporteSeleccionado === 'reporte-precios' && 
                            <>
                                <RC_Style.SubTitle>Generar reporte de precios</RC_Style.SubTitle>
                                <RC_Style.ContForm2 onSubmit={handleSubmitGetReport} padding_bottom={true}>

                                    {loadingEnterprisesData && <p>Cargando las empresas...</p>} 
                                    {!loadingEnterprisesData && !errorLoadingEnterprisesData && (
                                        <SelectEnterprise setEnterprise={setEnterprise} enterprise={enterprise} enterprises={enterprisesData} setMainRate={setMainRate} setPromotionalRate={setPromotionalRate} setRatesData={setRatesData}/>
                                    )}
                                    {!loadingEnterprisesData && errorLoadingEnterprisesData && <p>Ocurrio un error cargando las empresas</p>} 


                                    <div></div>

                                    {enterprise ? ( 
                                        <>
                                            
                                            {loadingRatesData && <p>Cargando las tarifas...</p>} 
                                            {!loadingRatesData && !errorLoadingRatesData && (
                                                <>
                                                    <SelectRate setRate={setMainRate} rate={mainRate} label={'Principal'} rates={ratesData.odooPricelist} />
                                                    <SelectRate setRate={setPromotionalRate} rate={promotionalRate} label={'Promocional'} rates={ratesData.odooPricelist} />
                                                    <SelectRate setRate={setPvpRate} rate={pvpRate} label={'Precio sugerido de venta'} rates={ratesData.odooPricelist} />
                                                    <SelectRate setRate={setCategories} rate={categories} label={'Categorias'} rates={ratesData.categories} multi={true}/>
                                                    <SelectRate setRate={setBrands} rate={brands} label={'Marcas'} rates={ratesData.brands} multi={true} isArrayString={true}/>
                                                     
                                                    <div style={{ marginBottom: '15px'}}>
                                                        <RC_Style.Label htmlFor="usuario">Ids de inventarios bloqueados</RC_Style.Label>
                                                        <div style={{display:'flex'}}>
                                                            <RC_Style.Input
                                                                type="text"
                                                                id="blockId"
                                                                value={blockId}
                                                                onChange={(e) => setBlockId(e.target.value)}
                                                            />
                                                            <RC_Style.Button onClick={addIdsToBlock} enterKeyHint={addIdsToBlock}>Agregar</RC_Style.Button>
                                                        </div>
                                                        {
                                                        blockedInventoryIds.length ? (
                                                            <div style={{maxWidth:430, width:'100%'}}>
                                                            <h6>Ids bloqueadas</h6>
                                                                <div style={{display:'grid', gridTemplateColumns:'repeat(4, 90px)', gap:17}}>
                                                                {
                                                                    blockedInventoryIds.map((id, index)=>(
                                                                            <p style={{
                                                                                width:100,
                                                                                textAlign:'center',
                                                                                backgroundColor:'#efefef',
                                                                                border:'1px solid #5a54d1', 
                                                                                cursor: 'pointer', 
                                                                                borderRadius: '.35rem', 
                                                                                padding: '.35rem'}} 
                                                                                key={index} 
                                                                                onClick={()=> removeIdToBlock(id)}>{id}</p>
                                                                        )
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <p>No hay ningun id bloqueado</p>
                                                        )
                                                    }
                                                    </div>
                                                   
                                                   
                                                    <SubmitButton label={'Generar reporte'}/>
                                                </>
                                            )}
                                            {errorLoadingRatesData && <p>Ocurrio un error cargando las tarifas</p>} 
                                        </>

                                    ): (null)}

                                </RC_Style.ContForm2>
                            </>
                        }

                    </>
                }


            </RC_Style.SubCont>
        </RC_Style.Cont>
    );
};
